<template>
	<div class="main-content">
		<div class="clearfix main-header" style="padding: 20px 40px;height: 30px;">
			<div class="left">
				<!-- <div>
					<el-select v-model="searchSelect" class="search-select">
						<el-option label="MSISDN" value="MSISDN"></el-option>
						<el-option label="ICCID" value="ICCID"></el-option>
					</el-select>
					<el-input v-model="searchStr" class="search-input ml10" placeholder="MSISDN/ICCID可按尾号查询"></el-input>
					<el-button id="global_search" @click="search" class="themed-button search-button" type="primary" icon="el-icon-search"></el-button>
				</div> -->
			</div>
			
			<div class="right">
				<!-- <span class="card-detial">共有卡 <span>{{topicData.totalNum}}</span> 张，正在使用 <span>{{topicData.usedNum}}</span> 张，待激活 <span>{{topicData.unActiveNum}}</span> 张，本月到期 <span>{{topicData.expireNum}}</span> 张</span> -->
				<span v-if='loginAgentType!=0' class="ring ml20" @click="toAnnounce"><i class="el-icon-message-solid"></i><span v-if="hasNotRead"></span></span>
				<span v-if='loginAgentType!=0' class="exit ml20">欢迎: <span>{{nickName}}</span></span>
				<span v-if='loginAgentType ==0' class="exit ml20">欢迎: <span>{{title}}</span></span>
				<span v-if='loginAgentType!=0' class="exit ml20" @click="editUserInfo">账号修改<i class="el-icon-edit"></i></span>
				<span class="exit ml20" @click="logout">退出<i class="el-icon-s-promotion"></i></span>
			</div>
		</div>
		<div style="background-color: #fff;">
			<el-tabs closable v-model="currentTab" @tab-click="handleClick" @tab-remove="removeTab">
				<el-tab-pane lazy v-for="(item) in cachedRouterTabs" :key="item.path" :label="item.title" :name="item.path"></el-tab-pane>
			</el-tabs>
		</div>

		<el-dialog title="修改账户" :visible.sync="dialogEditFormVisible" width="600" :destroy-on-close='true'>
            <el-form :model="editForm" :rules="rules" ref="editForm">
                <el-form-item label="代理商名" :label-width="formLabelWidth" prop="name">
                <el-input v-model="editForm.name" autocomplete="off" readonly></el-input>
                </el-form-item>
                <el-form-item label="账号密码" :label-width="formLabelWidth" prop="password">
                <el-input v-model="editForm.password" autocomplete="off" placeholder="填写新密码原密码将失效，不修改请勿填写"></el-input>
                </el-form-item>
                <el-form-item label="昵称" :label-width="formLabelWidth" prop="nickName">
                <el-input v-model="editForm.nickName" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="提现银行" :label-width="formLabelWidth" prop="bank">
                <el-input v-model="editForm.bank" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="提现账号" :label-width="formLabelWidth" prop="bankCode">
                <el-input v-model="editForm.bankCode" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="收款人" :label-width="formLabelWidth" prop="bankReceiver">
                <el-input v-model="editForm.bankReceiver" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="支付宝账号" :label-width="formLabelWidth" prop="zhifubao">
                <el-input v-model="editForm.zhifubao" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="支付宝收款人" :label-width="formLabelWidth" prop="zhifubaoReceiver">
                <el-input v-model="editForm.zhifubaoReceiver" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="身份证号" :label-width="formLabelWidth" prop="receiverIdCard">
                <el-input v-model="editForm.receiverIdCard" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
                <el-input v-model="editForm.phone" autocomplete="off" placeholder="填写新手机号原号码将失效，不修改请勿填写"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
                <el-input v-model="editForm.email" autocomplete="off"></el-input>
                </el-form-item>
				<el-form-item label="商户绑定" :label-width="formLabelWidth" v-if="showMerchantBind">
                	<span v-if="hasBindMerchant" style="margin-right:50px">{{merchantName}}({{auditStatus}})</span>
					<a v-if="showRebindMerchant" href="javascript:void(0);" style="margin-right:50px" class="send-code"  @click="bindMerchant">重新绑定</a>
					<a v-if="!hasBindMerchant" href="javascript:void(0);" style="margin-right:50px" class="send-code"  @click="bindMerchant">点击绑定</a>
                </el-form-item>

				<el-form-item label="微信昵称" :label-width="formLabelWidth">
					<span style="margin-right:50px">{{editForm.wxNickName}}</span>
				<a href="javascript:void(0);" style="margin-right:50px" class="send-code"  @click="getUrlCode" v-if="!editForm.wxNickName">扫码绑定</a>
				<a href="javascript:void(0);" style="margin-right:50px" class="send-code"  @click="getUrlCode" v-if="!!editForm.wxNickName">更新绑定</a>
				<a href="javascript:void(0);" style="margin-right:50px" class="send-code"  @click="addOtherWechart" v-if="!!editForm.wxNickName">添加额外收款微信</a>
                </el-form-item>
				
				<el-form-item label="注意" :label-width="formLabelWidth">
                	<div>
						<p style="color:#ff0000">请务必保证所有填写数据的准确性避免出现收款账户填写错误。必须确认微信、支付宝、银行卡等可以收款否则出现收款失败、收款账户错误等情况公司概不负责。</p>
						<p style="color:#ff0000" v-if=" parseInt(isGuangyao)&&(currentRole!=4)">公司打款优先选择微信打款，请大家先关注微信公众号“光曜IOT服务中心”或“欧本物联”公众号，关注后等待30分钟以上再来绑定微信。注意不要取消关注、有需要更换收款方式的务必在提现前更换</p>
					</div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureRecord('editForm')">确 认</el-button>
                 <el-button class="themed-button" type="info" @click="resetRecord('editForm')">重 置</el-button>
                <el-button class="themed-button" type="primary" @click="dialogEditFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>

		<el-dialog title="手机验证" :visible.sync="dialogCheckVisible" width="600" :destroy-on-close='true'>
            <el-form :model="checkForm" :rules="rules" ref="checkForm">
				<el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
                	<el-input :disabled="!!editForm.phone" v-model="checkForm.phone" autocomplete="off"></el-input>
                </el-form-item>

				<el-form-item label="验证码" :label-width="formLabelWidth" prop="smsCode">
                <el-input v-model="checkForm.smsCode" autocomplete="off"></el-input>
				<a href="javascript:void(0);" class="send-code" :class="{disabled:isDisabled}" @click="getMobileCode">{{sendCodeMag}}</a>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="sureChecked()">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogCheckVisible = false">取 消</el-button>
            </div>
        </el-dialog>

		<el-dialog
            title="微信扫码"
            :visible.sync="wechartURLDialogVisible"
            @close="closeCode"
            width="400px"
            center>
            <div style="width:150px;height:150px;margin:auto">
                <div id="qrcode" ref="qrcode" style="text-align:center"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sureScan">已绑定</el-button>
                <el-button @click="wechartURLDialogVisible = false">关闭</el-button>
            </span>
        </el-dialog>

		<el-dialog title="绑定商户号" :visible.sync="dialogBindMerchant" width="600" :destroy-on-close='true'>
            <el-form :model="bindMerchantForm"  ref="checkForm">
           <el-form-item
          label="商户类型:"
          :label-width="formLabelWidth"
          required
        >
          <el-select v-model="bindMerchantForm.type" @change="typeChange">
            <el-option label="微信" value="1"></el-option>
            <el-option label="乐刷" value="2"></el-option>
			<!-- <el-option label="银联" value="3"></el-option> -->
          </el-select>
        </el-form-item>

				<el-form-item label="名称：" :label-width="formLabelWidth" required >
					<el-input v-model="bindMerchantForm.name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="ID：" :label-width="formLabelWidth" required  >
					<el-input v-model="bindMerchantForm.mchId" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="交易秘钥：" :label-width="formLabelWidth" required >
					<el-input v-model="bindMerchantForm.key" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="异步回调密钥:" :label-width="formLabelWidth" required v-if="bindMerchantForm.type=='2'" >
					<el-input v-model="bindMerchantForm.keyAsync" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="证书：" :label-width="formLabelWidth" required  v-if="bindMerchantForm.type=='1'">
					<input class="file-input" type="file" ref='fileBindMerchant' @change="fileChangeMerchant" />
				</el-form-item>

                
				


				<el-form-item label="注意事项：" :label-width="formLabelWidth">
                	<div>
						<p style="color:#ff0000">1、商户号需要与{{parseInt(isGuangyao)?"光耀":""}}公众号关联</p>
						<p style="color:#ff0000">2、商户号请增加公众号支付页面路径</p>
					</div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="success" @click="submitBind()">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="dialogBindMerchant = false">取 消</el-button>
            </div>
        </el-dialog>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import QRCode from "qrcodejs2"
	import {tobTopic} from '@/api/statistics.js'
	import {uploadFile} from "@/api/payManagment.js"
	import {getAgentType,editAgentInfo,sendSmsCod,getWechartUrl,verifySmsCode,getAgentMerchant,applyAgentMerchant} from "@/api/agentManagement.js"
	export default {
		computed: {
			...mapGetters([
				'title',
				'hasNotRead',
				'hasNew',
				"currentRole",
				"isGuangyao"
			]),
			cachedRouterTabs(){
				return this.$store.getters.cachedRouterTabs
			},
			
			currentTab: {
				get() {
					return this.$store.getters.currentTab
				},
				set(v) {
					this.curtab = v//没用，就是为了消除警告
				}
				
			}
		},
		data() {
			return {
				searchSelect: 'MSISDN',
				searchStr:'',
				curtab:'',
				topicData:{},
				dialogEditFormVisible:false,
				nickName:'',
				showMerchantBind:false,  //代理商绑定显示
				hasBindMerchant:false,   //是否已绑定
				merchantName:'',
				auditStatus:'',
				editForm:{
					name:'',
					password:'',
					nickName:'',
					bank:'',
					bankCode:'',
					bankReceiver:'',
					zhifubao:'',
					zhifubaoReceiver:'',
					phone:'',
					receiverIdCard:'',
					wxNickName:'',
					email:"",
				},

				dialogCheckVisible:false,
				checkForm:{
					phone:'',
					smsCode:'',
				},
				isDisabled:false,
				sendCodeMag:'获取验证码',
				timer:null,
				rules:{},
				loginAgentType:'',
				formLabelWidth:'150px',

				wechartURLDialogVisible:false,
				wechartUrl:'',
				dialogBindMerchant:false,
				bindMerchantForm:{
					name:'',
					mchId:'',
					key:'',
					certPath:'',
					id:'',
					keyAsync:"",
					type:"1",
				},
				showRebindMerchant:false,
				reBindId:'',
				showName:this.isGuangyao?"光耀":"",
			}
		},
		watch:{
			searchSelect:function(newv,old){
				this.searchStr = ''
			},
		},
		created() {
			this.getTopData()
			this.getAgentType()
			console.log(this.isGuangyao)
			
		},
		methods: {
			typeChange(val){
      this.bindMerchantForm.certPath = ""
      this.bindMerchantForm.name = ""
      this.bindMerchantForm.mchId  = ""
      this.bindMerchantForm.key = ""
      this.bindMerchantForm.keyAsync = ""
			},
			toAnnounce(){
				if(this.loginAgentType ==0){
					this.$router.push({
						path:'/systemManagement/announcement'
					})
				}else{
					this.$router.push({
						path:'/agentManagement/agentAnnounce'
					})
				}
			},
			getMobileCode(){
				sendSmsCod(this.checkForm).then((res)=>{
					this.intervalTime()
				}).catch(()=>{
					this.intervalTime()
				})
				
			},
			intervalTime(){
				let time = 60
				this.isDisabled = true
				this.sendCodeMag =  time + " s 重新发送"
				window.clearInterval(this.timer)
				let that = this
				this.timer = window.setInterval(function () {
					if (time == 0) {
						that.isDisabled = false
						that.sendCodeMag = '获取验证码'
						window.clearInterval(that.timer)
					} else {
						that.sendCodeMag =  time + " s 重新发送"
						time = time - 1
					}
				}, 1000);
			},
			// 获取二维码
			getUrlCode(){
				getWechartUrl().then(res=>{
					this.wechartURLDialogVisible = true
					this.wechartUrl = res.url
					this.$nextTick(() => {
						this.crateQrcode()
					})
				})
			},

			// 添加其他收款微信
			addOtherWechart(){
				this.dialogEditFormVisible = false
				this.$router.push({
					path:'/payWechart'
				})
			},

			sureScan(){
				this.wechartURLDialogVisible = false
				// this.editUserInfo()
				this.showEditInfo()
			},

			crateQrcode () {
				let qr = new QRCode('qrcode', {
				width: 150,
				height: 150, // 高度
				text: this.wechartUrl // 二维码内容
            	})
			},
			
			closeCode(){
				this.wechartURLDialogVisible = false
				this.$refs.qrcode.innerHTML = ''
        	},

			getTopData(){
				tobTopic({}).then(res=>{
					this.topicData = res
				})
			},
			getAgentType(){
				getAgentType().then(res=>{
					this.loginAgentType = res.agentType
					this.nickName = res.nickName
				})
			},
			handleClick(tab) {
				if (this.$route.path != tab.name) {
					this.$router.push(tab.name)
				}
				
			},
			removeTab(targetName) {
				if (targetName == '/home') {
					return
				}
				let tabs = this.cachedRouterTabs;
				let activeName = this.currentTab;
				console.log(this.$route,188)
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.path === targetName) {
							let nextTab = tabs[index + 1] || tabs[index - 1];
							if (nextTab) {
								activeName = nextTab.path;
							}
						}
					});
					this.$router.push(activeName)
				}
				
				let current = activeName;
				let cachedRouterTabs = tabs.filter(tab => tab.path !== targetName);
				tabs.forEach(item=>{
					if(item.path == targetName){
						this.$store.commit('DEL_CACHED_VIEW',item.name)
					}
				})
				let cachedRouterPath = {current:current,paths:cachedRouterTabs}
				console.log(cachedRouterPath,200)
				this.$store.commit('SET_CACHED_ROUTER',cachedRouterPath)
				
			},
			search(){
				if (this.$route.path != '/enterpriseCard/listenterprise') {
					this.$router.push({path:'/enterpriseCard/listenterprise',query:{type:this.searchSelect,searchStr:this.searchStr}})
				}else{
					this.$store.commit('SET_SEARCH_ITEM',{type:this.searchSelect,searchStr:this.searchStr})
				}
				
				
			},

			editUserInfo(){
				getAgentType().then(res=>{
					this.editForm.phone= res.phone
					this.checkForm.phone = res.phone
					this.checkForm.smsCode = ''
					this.dialogCheckVisible = true
				})
			},
			sureChecked(){
				if(this.checkForm.smsCode == 'test123'){
						this.dialogCheckVisible = false
						this.dialogEditFormVisible = true
						this.showEditInfo()
				}else{
					verifySmsCode(this.checkForm).then(res=>{
					if(res.system_result_key == 0){
						this.dialogCheckVisible = false
						this.dialogEditFormVisible = true
						this.showEditInfo()
						
					}
					})
				}
			},

			showEditInfo(){
				getAgentType().then(res=>{
							this.editForm.name = res.name
							this.editForm.password= res.password
							this.editForm.nickName= res.nickName
							this.editForm.bank= res.bank
							this.editForm.bankCode= res.bankCode
							this.editForm.bankReceiver= res.bankReceiver
							this.editForm.zhifubao= res.zhifubao
							this.editForm.zhifubaoReceiver= res.zhifubaoReceiver
							this.editForm.phone= res.phone
							this.editForm.receiverIdCard = res.receiverIdCard
							this.editForm.wxNickName= res.wxNickName
							this.editForm.email = res.email
							this.showMerchantBind = res.showMerchantBind
							if(res.showMerchantBind){
								this.getMerchantBindStatus()
							}
				})
			},

			getMerchantBindStatus(){
				getAgentMerchant().then(res=>{
					if(res.entity){
						this.hasBindMerchant = true
						this.merchantName = res.entity.name
						let status= res.entity.auditStatus
						if(status == '1'){
							this.auditStatus = "待审核"
						}else if(status == '2'){
							this.auditStatus = "审核通过"
						}else if(status == '3'){
							this.auditStatus = "审核失败"
						}
						if(res.entity.auditStatus=='3'){
							this.showRebindMerchant = true
							this.reBindId = res.entity.id
						}else{
							this.showRebindMerchant = false
							this.reBindId = ''
						}
					}else{
						this.hasBindMerchant = false
					}
				})
			},

			bindMerchant(){
				this.resetBindMerchant()
				this.dialogBindMerchant = true
			},

			fileChangeMerchant(){
				let formData = new FormData();
				formData.append("file", this.$refs['fileBindMerchant'].files[0]);
				uploadFile(formData).then(res => {
					this.bindMerchantForm.certPath = res.filePath
				})
			},

			resetBindMerchant(){
				this.bindMerchantForm={
					name:'',
					mchId:'',
					key:'',
					certPath:'',
					id:'',
					keyAsync:"",
					type:"1",
				}
			},

			submitBind(){
				this.bindMerchantForm.id = this.reBindId
				applyAgentMerchant(this.bindMerchantForm).then(res=>{
					if (res.system_result_key == 0) {
                       this.$message({
							showClose: true,
							message: '提交成功',
							type: 'success',
						})
						
						this.dialogBindMerchant=false
						this.showEditInfo()
                    } else {
						this.$message({
							showClose: true,
							message: res.slide_msg_message_key,
						})
						this.dialogBindMerchant=false
					} 
				})
			},

			sureRecord(formName){
				editAgentInfo(this.editForm).then(res=>{
                   if (res.system_result_key == 0) {
                       this.$message({
							showClose: true,
							message: '修改成功',
							type: 'success',
						})
						this.getAgentType()
                        this.$refs[formName].resetFields()
                        this.dialogEditFormVisible=false
						/* 修改记录da1391d4 */
                    } else if(res.slide_msg_key == 1){
						this.$message({
							showClose: true,
							message: res.slide_msg_message_key,
						})
						this.$refs[formName].resetFields()
						this.dialogEditFormVisible=false
					} 
					
                })
			},
			resetRecord(formName){
				this.$refs[formName].resetFields()
			},
			logout(){
				this.$confirm("确定要退出吗？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.dispatch('LogOut')
				})
			}
			
		}
		

	}
</script>

<style>
	.main-content .main-header .search-select {
		display: inline-block;
		width: 90px;
	}

	.main-content .main-header .search-input {
		display: inline-block;
		width: 180px;
	}

	.main-content .main-header .search-button {
		padding: 8px 10px !important;
	}

	.main-content .main-header .search-button i {
		font-size: 14px !important;
	}

	.main-content .main-header .el-input .el-input__inner,
	.main-content .main-header .el-input .el-input__inner .el-range-input {
		border: none;
	}

	.main-content .main-header .card-detial {
		color: #989898;
	}

	.main-content .main-header .card-detial span {
		color: #f06400;
	}

	.main-content .main-header .ring {
		position: relative;
	}

	.main-content .main-header .ring i {
		font-size: 16px;
		color: #989898;
	}

	.main-content .main-header .ring span {
		background-color: #f06400;
		width: 6px;
		height: 6px;
		border-radius: 3px;
		display: block;
		position: absolute;
		right: 0;
		top: 0;
	}

	.main-content .main-header .exit,
	.main-content .main-header .exit i {
		color: #989898;
		font-weight: normal;
		cursor: pointer;
	}

	/* tabs */
	.main-content .el-tabs__active-bar,
	.main-content .el-tabs__content,
	.main-content .el-tabs__nav-wrap::after {
		display: none !important;
	}

	.main-content .el-tabs__header {
		margin: 0 !important;
	}

	.main-content .el-tabs__item {
		color: #3e3e3e;
		font-weight: 600;
		height: 44px !important;
		line-height: 44px !important;
		padding: 0px 8px 0px 24px !important;
		cursor: pointer;
		border-right: 1px solid #f1f1f1;
	}

	.main-content .el-tabs__item span {
		margin-left: 10px !important;
	}

	.main-content .el-tabs__item:hover {
		background-color: #ffebdd;
		color: #3e3e3e;
	}

	.main-content .el-tabs__item.is-active {
		color: #fff;
		background-color: #f06400;
	}

	.send-code.disabled{pointer-events:none}

</style>
