<template>
</template>

<script>
	import {host} from '@/api/request.js'
	export default{
		beforeRouteEnter(to, from, next) {
			const url = host + 'pages/api-doc.html'
			window.open(url,'_blank')
			next(false)
		}
	}
</script>

<style>
</style>
